import React from "react";
import { useContext } from "react";
import Table from "react-bootstrap/Table";
import { buildDisplayItems } from "../../../services/display_item_helper";
import { anyChildIsRendered } from "../../../services/dependencies/dependency_helper";
import { FieldDataContext, FormContext, InputContext } from "../../../contexts";

const ProspectTable = ({ displayItem }) => {
  // Get required values from our context
  const { formValues } = useContext(FieldDataContext);
  const { fields, readonly } = useContext(FormContext);

  // Filter our table rows, keeping only those in which a field passes its dependency
  const filteredRows = displayItem.rows.filter((row) => {
    return anyChildIsRendered(row, formValues, fields, readonly) == true;
  });

  // If all rows have been filtered, don't render the table
  if (filteredRows.length == 0) {
    return false;
  }

  const tableHeader = displayItem.headers.map((header, index) => {
    return <th key={index}>{header}</th>;
  });

  const buildRow = (row, index) => {
    return <tr key={index}>{buildDisplayItems(row)}</tr>;
  };

  const rows = filteredRows.map((row, index) => {
    return buildRow(row, index);
  });

  return (
    <InputContext.Provider value={{ inputContext: "table" }}>
      <Table bordered className="mt-3 table-regular-scale fs-7 bg-white">
        <thead>
          <tr className="custom-header-background">{tableHeader}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </InputContext.Provider>
  );
};

export default ProspectTable;
