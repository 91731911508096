import React from "react";
import { useState, useMemo } from "react";
import { getCsrfToken } from "../services/csrf_helper";
import { FormContext, FieldDataContext, InputContext } from "../contexts";
import { buildDisplayItems } from "../services/display_item_helper";
import ChangeReason from "../components/data_entry/ChangeReason";
import { updateAllDependencies } from "../services/dependencies/dependency_helper";
import DropDownLinks from "../components/form_navigation/DropdownLinks";
import ButtonLink from "../components/form_navigation/ButtonLink";
import { Form, Row, Col } from "react-bootstrap";
import {
  checkForEntered,
  processFormValues,
} from "../services/form_page_helper";

const FormPage = ({
  is_questionnaire,
  is_admin,
  postLocation,
  backLocation,
  readonly,
  persisted,
  displayItems,
  fields,
  subforms,
  lookups,
  siteMetaLookups,
  studyListOptions,
  tables,
  formData,
  dependencyData,
  generalErrors,
  changeReasons,
  changeReasonData,
  submitText,
  externalReferences,
  formLinks,
  currentForm,
  previousForm,
  nextForm,
  auditPage,
}) => {
  const csrfToken = getCsrfToken();
  // We're using useMemo to prevent this running repeatedly. An alternative would be to do this processing server side
  let processedFormValues = useMemo(() => {
    return processFormValues(fields, formData, externalReferences);
  }, []);

  // Update the dependencies when the page loads for the first time
  const [originalFormValues, originalSubformValues] = useMemo(() => {
    return updateAllDependencies(
      dependencyData,
      processedFormValues,
      subforms,
      externalReferences,
      auditPage
    );
  }, []);

  const [formValues, setFormValues] = useState(originalFormValues);

  const [subformsValues, setSubformsValues] = useState(originalSubformValues);

  /**
   * Updates key: [identifier] in our formValues object with the provided value
   * Keeps the "changed" and "entered" flags up to date and rechecks dependencies
   * @param {string} identifier
   * @param {*} new_value
   */
  const updateFormValue = (identifier, new_value) => {
    // Get a reference to our field data
    const fieldData = formValues[identifier];

    // Make a copy of our field data and update the value property
    let updatedFieldData = {
      ...fieldData,
      value: new_value,
    };

    updatedFieldData.entered = checkForEntered(updatedFieldData);

    // Make a copy of the formValues object and update our field
    const updatedFormValues = {
      ...formValues,
      [identifier]: updatedFieldData,
    };

    const [newFormValues, newSubformValues] = updateAllDependencies(
      dependencyData,
      updatedFormValues,
      subformsValues,
      externalReferences,
      auditPage
    );

    setFormValues(newFormValues);
    setSubformsValues(newSubformValues);
  };

  const setSubformsValuesWithDependencies = (newData) => {
    const [newFormValues, newSubformValues] = updateAllDependencies(
      dependencyData,
      formValues,
      newData,
      externalReferences,
      auditPage
    );

    setFormValues(newFormValues);
    setSubformsValues(newSubformValues);
  };

  const addNewRowTo = (subformIdentifer) => {
    const subform = subformsValues.find((subform) => {
      return subform.identifier === subformIdentifer;
    });
    const newRows = [...subform.subformData, { ...subform.templateData }];
    const newSubform = { ...subform, subformData: newRows };

    const newSubformData = subformsValues.map((subform) => {
      if (subform.identifier === subformIdentifer) {
        return newSubform;
      } else {
        return subform;
      }
    });

    setSubformsValuesWithDependencies(newSubformData);
  };

  const replaceSubformRow = (subform, subformIndex, newSubformRow) => {
    let newSubformRows = [...subform.subformData];
    newSubformRows[subformIndex] = newSubformRow;

    const newSubform = { ...subform, subformData: newSubformRows };

    const newSubformData = subformsValues.map((subform) => {
      if (newSubform.identifier === subform.identifier) {
        return newSubform;
      } else {
        return subform;
      }
    });

    setSubformsValuesWithDependencies(newSubformData);
  };

  const updateFieldValueForSubform = (
    subformIdentifer,
    subformIndex,
    fieldIdentifier,
    value
  ) => {
    const subform = subformsValues.find((subform) => {
      return subform.identifier === subformIdentifer;
    });

    const subformRow = subform.subformData[subformIndex];

    const subformFields = subformRow.fields;

    let newFieldData = { ...subformFields[fieldIdentifier], value: value };

    newFieldData.entered = checkForEntered(newFieldData);

    const newSubformFields = {
      ...subformFields,
      [fieldIdentifier]: newFieldData,
    };

    const newSubformRow = { ...subformRow, fields: newSubformFields };

    replaceSubformRow(subform, subformIndex, newSubformRow);
  };

  const reorderSubformRows = (subformIdentifer, subformIndex, moveValue) => {
    const subform = subformsValues.find((subform) => {
      return subform.identifier === subformIdentifer;
    });

    const subformRow = subform.subformData[subformIndex];

    let newSubformRows = [...subform.subformData];

    const newIndex = subformIndex + moveValue;

    // Do nothing if trying to move item out of available range. UI should block this
    if (newIndex < 0 || newIndex >= newSubformRows.length) {
      return null;
    }

    newSubformRows.splice(subformIndex, 1);

    newSubformRows.splice(subformIndex + moveValue, 0, subformRow);

    const newSubform = { ...subform, subformData: newSubformRows };

    const newSubformData = subformsValues.map((subform) => {
      if (newSubform.identifier === subform.identifier) {
        return newSubform;
      } else {
        return subform;
      }
    });

    setSubformsValuesWithDependencies(newSubformData);
  };

  const handleSubformDelete = (subformIdentifer, subformIndex, delete_row) => {
    const subform = subformsValues.find((subform) => {
      return subform.identifier === subformIdentifer;
    });

    const subformRow = subform.subformData[subformIndex];

    const newSubformRow = { ...subformRow, marked_for_delete: delete_row };

    replaceSubformRow(subform, subformIndex, newSubformRow);
  };

  /** Our display item jsx */
  const renderedDisplayItems = buildDisplayItems(displayItems);

  /** The change reason/info jsx (or null if not applicable) */
  const editInfo = persisted && (
    <ChangeReason
      changeReasons={changeReasons}
      changeReasonData={changeReasonData}
    ></ChangeReason>
  );

  /** The patch hidden input for rails */
  const formPatch = persisted && (
    <input type="hidden" name="_method" value="patch" autoComplete="off" />
  );

  /** The general error messages for the form (displays one alert per error) */
  const errors = generalErrors.form
    ? generalErrors.form.map((error, index) => {
        return (
          <div className="alert alert-danger" role="alert" key={index}>
            {error}
          </div>
        );
      })
    : null;

  // Check if any of our values have been entered or changed
  let anyEntered = false;
  for (const key in formValues) {
    const formValue = formValues[key];
    if (formValue.entered) {
      anyEntered = true;
    }
  }
  if (!anyEntered) {
    subformsValues.forEach((subform) => {
      subform.subformData.forEach((subformData) => {
        for (const key in subformData.fields) {
          const formValue = subformData.fields[key];
          if (formValue.entered) {
            anyEntered = true;
          }
        }
      });
    });
  }

  let formContents;
  if (readonly) {
    // Don't render a form element if the form is readonly
    formContents = (
      <div>
        {!auditPage && !is_questionnaire && (
          <div className="mt-4 col-12 col-xl-10 d-flex">
            <div className="btn-group nav-btn-group mx-auto">
              {previousForm && !previousForm["newOngoing"] ? (
                <ButtonLink
                  className="btn btn-outline-secondary col form-nav-link"
                  button_text="Previous form"
                  link={previousForm["url"]}
                />
              ) : (
                <div className="btn btn-outline-secondary col disabled form-nav-link">
                  Previous Form
                </div>
              )}
              {is_questionnaire ?? (
                <DropDownLinks button_text={currentForm} links={formLinks} />
              )}
              {nextForm ? (
                <ButtonLink
                  className="btn btn-outline-secondary col form-nav-link"
                  button_text="Next form"
                  link={nextForm["url"]}
                />
              ) : (
                <div className="btn btn-outline-secondary col disabled form-nav-link">
                  Next Form
                </div>
              )}
            </div>
          </div>
        )}

        <div className="mt-4 col-12 col-xl-10">{renderedDisplayItems}</div>
      </div>
    );
  } else {
    formContents = (
      <div className="mt-4 mb-3 col-12 col-xl-10">
        {errors}
        <form
          action={postLocation}
          method="post"
          id="form-element"
          autoComplete="off"
        >
          {!is_questionnaire && editInfo}
          {renderedDisplayItems}
          {/* Include our csrf token */}
          <input
            type="hidden"
            name="authenticity_token"
            value={csrfToken || ""}
            autoComplete="off"
          />
          {formPatch}
          <Row>
            <Col>
              <div className="mt-3 d-flex">
                <button
                  id="save-button"
                  className="btn btn-primary me-2"
                  type="submit"
                  disabled={!anyEntered}
                >
                  <i className="fas fa-check me-1"></i>
                  {submitText}
                </button>
                <a href={backLocation} className="btn btn-outline-secondary">
                  <i className="fas fa-times me-1"></i>
                  Cancel
                </a>
                {is_admin && (
                  <Form.Check
                    name="skip_validations"
                    label="Skip Validations?"
                    className="ms-auto"
                    id="skip-validations-checkbox"
                  />
                )}
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }

  return (
    <FormContext.Provider
      value={{
        readonly,
        fields,
        subformsValues,
        lookups,
        siteMetaLookups,
        studyListOptions,
        tables,
        formValues,
        updateFormValue,
        generalErrors,
        addNewRowTo,
        updateFieldValueForSubform,
        handleSubformDelete,
        reorderSubformRows,
        externalReferences,
        auditPage,
        is_questionnaire,
      }}
    >
      <FieldDataContext.Provider
        value={{
          fields,
          formValues,
          updateFormValue,
          buildInputName: is_questionnaire
            ? (fieldIdentifier) => {
                return `questionnaire_management_log[${fieldIdentifier}]`;
              }
            : (fieldIdentifier) => {
                return `form[${fieldIdentifier}]`;
              },
          buildUniqueIdentifer: (fieldIdentifier) => {
            return `field_${fieldIdentifier}`;
          },
        }}
      >
        <InputContext.Provider value={{ inputContext: null }}>
          {formContents}
        </InputContext.Provider>
      </FieldDataContext.Provider>
    </FormContext.Provider>
  );
};

export default FormPage;
